var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('assignDriver.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('assignDriver.pageTitle'),
                href: '/admin/agency/driver-assigns'
            },
            {
                text: _vm.$t('create'),
                active: true
            }
        ]}}),_c('ts-loading-banner',{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"row tw-space-y-3"},[_c('div',{staticClass:"col-md-12"},[_c('ts-panel',[_c('ts-panel-wrapper',[_c('form',[_c('fieldset',{staticClass:"tw-border-2 tw-space-y-3"},[_c('legend',{staticClass:"tw-uppercase tw-font-bold tw-text-sm"},[_vm._v(" "+_vm._s(_vm.$t("assignDriver.optionFilter"))+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 tw-space-y-2"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("assignDriver.driver")))]),_c('a-select',{staticStyle:{"width":"100%"},attrs:{"show-search":"","default-active-first-option":false,"show-arrow":false,"filter-option":false,"not-found-content":null,"placeholder":_vm.$t(
                                                    'assignDriver.typeToSearch'
                                                )},on:{"search":_vm.handleDriverSearch},model:{value:(_vm.model.driver_id),callback:function ($$v) {_vm.$set(_vm.model, "driver_id", $$v)},expression:"model.driver_id"}},_vm._l((_vm.driverList),function(c){return _c('a-select-option',{key:c.driver_id},[_vm._v(" "+_vm._s(c.driver_name)+" ")])}),1),(_vm.errors.has('driver_id'))?_c('div',{staticClass:"tw-text-red-500"},[_vm._v(" "+_vm._s(_vm.errors.first("driver_id"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 tw-space-y-2"},[_c('label',[_vm._v(_vm._s(_vm.$t("assignDriver.zone")))]),_c('a-select',{staticStyle:{"width":"100%"},attrs:{"show-search":"","default-active-first-option":false,"show-arrow":false,"filter-option":false,"not-found-content":null,"placeholder":_vm.$t(
                                                    'assignDriver.typeToSearch'
                                                )},on:{"search":_vm.handleZoneSearch},model:{value:(_vm.zone_id),callback:function ($$v) {_vm.zone_id=$$v},expression:"zone_id"}},_vm._l((_vm.zoneList),function(z){return _c('a-select-option',{key:z.zone_id},[_vm._v(" "+_vm._s(z.zone_name)+" ")])}),1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 tw-space-y-3"},[_c('SearchBox',{attrs:{"placeholder":_vm.$t(
                                                    'assignDriver.searchPlaceholder'
                                                )},on:{"input":_vm.onSearchInput},model:{value:(_vm.search_box),callback:function ($$v) {_vm.search_box=$$v},expression:"search_box"}})],1),_c('div',{staticClass:"col-md-6 tw-space-y-3"},[_c('a-select',{staticStyle:{"width":"100%"},attrs:{"show-search":"","default-active-first-option":false,"show-arrow":false,"filter-option":false,"not-found-content":null,"placeholder":_vm.$t(
                                                    'assignDriver.receiverPhone'
                                                )},on:{"change":_vm.onReceiverPhoneChange,"search":_vm.handleSearchReceiverPhone}},_vm._l((_vm.receiverPhone),function(p){return _c('a-select-option',{key:p.booking_id,attrs:{"value":p.booking_id}},[_vm._v(" "+_vm._s(p.receiver_phone + " (" + (p.booking_number + ", " + p.booking_time + ": " + p.delivery_status + (p.status_remark ? "-" + p.status_remark : "")) + ")")+" ")])}),1)],1)])])])])],1)],1),_c('div',{staticClass:"col-md-12"},[_c('ts-panel',[_c('ts-panel-wrapper',[_c('h4',{staticClass:"tw-uppercase"},[_vm._v(" "+_vm._s(_vm.$t("assignDriver.packageList"))+" ")]),_c('div',{staticClass:"tw-overflow-y-scroll tw-mb-3",staticStyle:{"min-height":"30vh","max-height":"40vh"}},[_c('ts-table',{attrs:{"columns":_vm.columns,"records":_vm.selectedBooking,"loading":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                var record = ref.record;
return [_c('td',[_vm._v(_vm._s(record.booking_number))]),_c('td',[_vm._v(_vm._s(record.shop_name))]),_c('td',[_vm._v(_vm._s(record.receiver_phone))]),_c('td',[_vm._v(_vm._s(record.package_type_kh))]),_c('td',{staticClass:"tw-text-center"},[_vm._v(" "+_vm._s(record.number_of_package)+" ")]),_c('td',{staticClass:"tw-text-center"},[_c('ts-accounting-format',{attrs:{"digit":2,"value":record.purchase_amount}})],1),_c('td',[_vm._v(_vm._s(record.destination))]),_c('td',{staticClass:"tw-w-6 tw-text-center"},[_c('a-popconfirm',{attrs:{"placement":"leftTop","title":_vm.$t('are_you_sure_to_delete'),"ok-text":_vm.$t('yes'),"cancel-text":_vm.$t('no')},on:{"confirm":function($event){return _vm.deleteRecord(record)}}},[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-trash text-red"})])])],1)]}}])})],1),_c('div',{staticClass:"row tw-mt-4"},[_c('div',{staticClass:"col-md-12 tw-space-x-4 tw-flex tw-justify-end"},[_c('ts-button',{attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("assign")))])],1)])])],1)],1)])]),_c('a-modal',{attrs:{"title":_vm.$t('pdf'),"width":"50%","footer":null},model:{value:(_vm.preview_pdf),callback:function ($$v) {_vm.preview_pdf=$$v},expression:"preview_pdf"}},[_c('ts-preview-pdf',{attrs:{"src":_vm.src},model:{value:(_vm.waiting),callback:function ($$v) {_vm.waiting=$$v},expression:"waiting"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }