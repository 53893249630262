<template>
    <div>
        <ts-page-title
            :title="$t('assignDriver.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('assignDriver.pageTitle'),
                    href: '/admin/agency/driver-assigns'
                },
                {
                    text: $t('create'),
                    active: true
                }
            ]"
        />
        <ts-loading-banner :loading="loading">
            <div class="row tw-space-y-3">
                <div class="col-md-12">
                    <ts-panel>
                        <ts-panel-wrapper>
                            <form>
                                <fieldset class="tw-border-2 tw-space-y-3">
                                    <legend
                                        class="tw-uppercase tw-font-bold tw-text-sm"
                                    >
                                        {{ $t("assignDriver.optionFilter") }}
                                    </legend>
                                    <div class="row">
                                        <div class="col-md-12 tw-space-y-2">
                                            <label class="required">{{
                                                $t("assignDriver.driver")
                                            }}</label>
                                            <a-select
                                                show-search
                                                v-model="model.driver_id"
                                                style="width: 100%"
                                                :default-active-first-option="
                                                    false
                                                "
                                                :show-arrow="false"
                                                :filter-option="false"
                                                :not-found-content="null"
                                                :placeholder="
                                                    $t(
                                                        'assignDriver.typeToSearch'
                                                    )
                                                "
                                                @search="handleDriverSearch"
                                            >
                                                <a-select-option
                                                    v-for="c in driverList"
                                                    :key="c.driver_id"
                                                >
                                                    {{ c.driver_name }}
                                                </a-select-option>
                                            </a-select>
                                            <div
                                                class="tw-text-red-500"
                                                v-if="errors.has('driver_id')"
                                            >
                                                {{ errors.first("driver_id") }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 tw-space-y-2">
                                            <label>{{
                                                $t("assignDriver.zone")
                                            }}</label>
                                            <a-select
                                                v-model="zone_id"
                                                show-search
                                                style="width: 100%"
                                                :default-active-first-option="
                                                    false
                                                "
                                                :show-arrow="false"
                                                :filter-option="false"
                                                :not-found-content="null"
                                                :placeholder="
                                                    $t(
                                                        'assignDriver.typeToSearch'
                                                    )
                                                "
                                                @search="handleZoneSearch"
                                            >
                                                <!-- @change="onZoneChange" -->
                                                <a-select-option
                                                    v-for="z in zoneList"
                                                    :key="z.zone_id"
                                                >
                                                    {{ z.zone_name }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 tw-space-y-3">
                                            <SearchBox
                                                v-model="search_box"
                                                @input="onSearchInput"
                                                :placeholder="
                                                    $t(
                                                        'assignDriver.searchPlaceholder'
                                                    )
                                                "
                                            />
                                        </div>
                                        <div class="col-md-6 tw-space-y-3">
                                            <a-select
                                                show-search
                                                style="width: 100%"
                                                :default-active-first-option="
                                                    false
                                                "
                                                :show-arrow="false"
                                                :filter-option="false"
                                                :not-found-content="null"
                                                :placeholder="
                                                    $t(
                                                        'assignDriver.receiverPhone'
                                                    )
                                                "
                                                @change="onReceiverPhoneChange"
                                                @search="
                                                    handleSearchReceiverPhone
                                                "
                                            >
                                                <a-select-option
                                                    v-for="p in receiverPhone"
                                                    :key="p.booking_id"
                                                    :value="p.booking_id"
                                                >
                                                    {{
                                                        p.receiver_phone +
                                                            ` (${p.booking_number +
                                                                ", " +
                                                                p.booking_time +
                                                                ": " +
                                                                p.delivery_status +
                                                                (p.status_remark
                                                                    ? "-" +
                                                                      p.status_remark
                                                                    : "")})`
                                                    }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </ts-panel-wrapper>
                    </ts-panel>
                </div>
                <div class="col-md-12">
                    <ts-panel>
                        <ts-panel-wrapper>
                            <h4 class="tw-uppercase">
                                {{ $t("assignDriver.packageList") }}
                            </h4>
                            <div
                                style="min-height:30vh; max-height:40vh"
                                class="tw-overflow-y-scroll tw-mb-3"
                            >
                                <ts-table
                                    :columns="columns"
                                    :records="selectedBooking"
                                    :loading="false"
                                >
                                    <template v-slot="{ record }">
                                        <td>{{ record.booking_number }}</td>
                                        <td>{{ record.shop_name }}</td>
                                        <td>{{ record.receiver_phone }}</td>
                                        <td>{{ record.package_type_kh }}</td>
                                        <td class="tw-text-center">
                                            {{ record.number_of_package }}
                                        </td>
                                        <td class="tw-text-center">
                                            <ts-accounting-format
                                                :digit="2"
                                                :value="record.purchase_amount"
                                            />
                                        </td>
                                        <td>{{ record.destination }}</td>
                                        <td class="tw-w-6 tw-text-center">
                                            <a-popconfirm
                                                placement="leftTop"
                                                :title="
                                                    $t('are_you_sure_to_delete')
                                                "
                                                :ok-text="$t('yes')"
                                                :cancel-text="$t('no')"
                                                @confirm="deleteRecord(record)"
                                            >
                                                <a href="#">
                                                    <i
                                                        class="fa fa-trash text-red"
                                                    ></i>
                                                </a>
                                            </a-popconfirm>
                                        </td>
                                    </template>
                                </ts-table>
                            </div>
                            <div class="row tw-mt-4">
                                <div
                                    class="col-md-12 tw-space-x-4 tw-flex tw-justify-end"
                                >
                                    <ts-button
                                        color="primary"
                                        @click.prevent="onSave"
                                        >{{ $t("assign") }}</ts-button
                                    >
                                </div>
                            </div>
                        </ts-panel-wrapper>
                    </ts-panel>
                </div>
            </div>
        </ts-loading-banner>
        <a-modal
            :title="$t('pdf')"
            width="50%"
            v-model="preview_pdf"
            :footer="null"
        >
            <ts-preview-pdf v-model="waiting" :src="src" />
        </a-modal>
    </div>
</template>

<script>
import SearchBox from "./components/search-box.vue";
// import BookingList from "./components/booking-list.vue";
import { mapActions } from "vuex";
import { debounce } from "lodash";
import { Errors } from "form-backend-validation";
export default {
    name: "assignDriverCreate",
    components: {
        SearchBox
        // BookingList
    },
    data() {
        return {
            waiting: false,
            preview_pdf: false,
            src: "",
            errors: new Errors(),
            loading: false,
            search_box: null,
            zone_id: null,
            bookingList: [],
            selectedBooking: [],
            model: {
                driver_id: null,
                booking_list_id: []
            },
            receiverPhone: [],
            driverList: [],
            zoneList: []
        };
    },
    computed: {
        columns() {
            return [
                { name: this.$t("bookingPickup.bookingNumber") },
                { name: this.$t("bookingPickup.shopName") },
                { name: this.$t("bookingPickup.receiverPhone") },
                { name: this.$t("bookingPickup.packageType") },
                {
                    name: this.$t("bookingPickup.numberOfPackage"),
                    style: "text-align:center"
                },
                { name: this.$t("bookingPickup.purchaseAmount") },
                { name: this.$t("bookingPickup.location") },
                { name: this.$t("actions") }
            ];
        }
    },
    methods: {
        ...mapActions("agency/assignDriver", [
            "store",
            "searchBooking",
            "getFormViewData"
        ]),
        handleDriverSearch: debounce(async function(value) {
            this.loading = true;
            let rep = await this.getFormViewData({
                index: "driver",
                params: {
                    fnName: "driver",
                    search_driver: value
                }
            });
            this.driverList = rep.data["driver"];
            this.loading = false;
        }, 1000),
        handleZoneSearch: debounce(async function(value) {
            if (!value) return false;
            this.loading = true;
            var rep = await this.getFormViewData({
                index: "zone",
                params: {
                    fnName: "zone",
                    search_zone: value
                }
            });
            this.loading = false;
            this.zoneList = rep.data["zone"];
        }, 1000),
        onZoneChange() {
            this.onSearchInput(this.search_box);
        },
        onSearchInput(value) {
            if (!value) return false;
            this.getBooking({
                zone_id: this.zone_id,
                search_booking: value
            });
        },
        getBooking(value) {
            this.loading = true;
            this.searchBooking(value)
                .then(response => {
                    response.data.forEach(el => {
                        this.addItem(el);
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleSearchReceiverPhone: debounce(async function(value) {
            if (!value) return false;
            this.loading = true;
            let rep = await this.getFormViewData({
                index: "receiver_phone",
                params: {
                    fnName: "receiver_phone",
                    receiver_phone: value,
                    zone_id: this.zone_id
                }
            });
            this.receiverPhone = rep.data["receiver_phone"];
            this.loading = false;
        }, 500),
        async fetchPhoneNumber() {
            this.loading = true;
            let rep = await this.getFormViewData({
                index: "receiver_phone,driver",
                params: {
                    fnName: "receiver_phone,driver",
                    receiver_phone: null,
                    zone_id: this.zone_id
                }
            });
            this.receiverPhone = rep.data["receiver_phone"];
            this.driverList = rep.data["driver"];
            this.loading = false;
        },
        onReceiverPhoneChange(value) {
            this.getBooking({
                zone_id: this.zone_id,
                search_booking_id: value
            });
        },
        addItem(item) {
            var existing = this.model.booking_list_id.find(
                id => id == item.booking_id
            );
            if (!existing) {
                this.selectedBooking.push(item);
                this.model.booking_list_id.push(item.booking_id);
            }
        },
        deleteRecord(record) {
            this.selectedBooking.splice(
                this.selectedBooking.indexOf(
                    this.selectedBooking.find(
                        b => b.booking_id == record.booking_id
                    )
                ),
                1
            );

            this.model.booking_list_id.splice(
                this.model.booking_list_id.indexOf(record.booking_id),
                1
            );
        },
        onSave() {
            this.src = "";
            this.errors = new Errors();
            this.waiting = true;
            this.preview_pdf = true;
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.clearInput();
                    this.src = response.url;
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                    this.preview_pdf = false;
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        clearInput() {
            this.bookingList = [];
            this.selectedBooking = [];
            (this.model.driver_id = undefined),
                (this.model.booking_list_id = []);
            this.removeKeyData();
        },
        setValueModel() {
            if (localStorage.getItem("model_assign")) {
                var data = JSON.parse(localStorage.getItem("model_assign"));
                this.bookingList = data.bookingList;
                this.selectedBooking = data.selectedBooking;
                this.model = data.model;
                this.receiverPhone = data.receiverPhone;
                this.driverList = data.driverList;
                this.zoneList = data.zoneList;
            }
        },
        removeKeyData() {
            localStorage.removeItem("model_assign");
        }
    },
    watch: {
        model: {
            handler() {
                localStorage.setItem(
                    "model_assign",
                    JSON.stringify({
                        model: this.model,
                        selectedBooking: this.selectedBooking,
                        bookingList: this.bookingList,
                        receiverPhone: this.receiverPhone,
                        driverList: this.driverList,
                        zoneList: this.zoneList
                    })
                );
            },
            deep: true
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchPhoneNumber();
            vm.setValueModel();
        });
    }
};
</script>
